import React, { useEffect, useState } from 'react';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useTranslation } from 'next-i18next';
import axios from 'axios';
import { NextSeo, WebPageJsonLd } from 'next-seo';
import Layout from '../../components/general/Layout';
import Header from '../../components/general/Header';
import { CANONICAL_URL, ENDPOINT } from '../../api/constants';
import GameApp from '../../components/games/index';

export default function Home({ data }) {
  const { canonicalUrlEnd } = data;
  const { t, i18n } = useTranslation('common');
  const [schemaData, setSchemaData] = useState('');
  const selectedLanguage = i18n.language;

  const slotSchemeData = {
    '@context': 'https://schema.org',
    '@type': 'Casino',
    name: 'PPX7',
    url: 'https://www.ppx7.com/pt-br/slots',
    logo: 'https://www.ppx7.com/logo.png',
    description: 'Slots Online | Jogos de Máquinas Slots | Cassino PPX7',
    descriptionPt: 'Jogue nas slots do Fortune Tiger em PPX7.com, o principal cassino online do Brasil. Desfrute de um jogo emocionante e tenha a chance de ganhar muito.',
    title: 'Slots - Fortune Tiger - PPX7.com',
    titlePt: 'Caça-níqueis - Fortune Tiger - PPX7.com',
  };

  const instantGamesSchemeData = {
    '@context': 'https://schema.org',
    '@type': 'OnlineCasino',
    name: 'PPX7 Jogos Instantâneos',
    url: 'https://www.ppx7.com/pt-br/instant-games',
    logo: 'https://www.ppx7.com/logo.png',
    description: 'Jogos Instantâneos - Aposte Agora e Ganhe Bônus de Boas Vindas na PPX7',
    descriptionPt: 'Experimente vitórias instantâneas com o Fortune Tiger em PPX7.com, o principal cassino online do Brasil. Desfrute de jogos rápidos e grandes recompensas.',
    title: 'Instants - Fortune Tiger - PPX7.com',
    titlePt: 'Jogos Instantâneos - Fortune Tiger - PPX7.com',
  };

  const liveCasinoSchemeData = {
    '@context': 'https://schema.org',
    '@type': 'OnlineCasino',
    name: 'PPX7 Cassino Ao Vivo',
    url: 'https://www.ppx7.com/pt-br/live-casino',
    logo: 'https://www.ppx7.com/logo.png',
    description: 'Junte-se a PPX7 para Jogos Premium de Cassino Ao Vivo',
    descriptionPt: 'Experimente a emoção do Cassino ao Vivo com o Fortune Tiger em PPX7.com, o principal cassino online do Brasil. Desfrute de jogos em tempo real e grandes vitórias.',
    title: 'Live Casino - Fortune Tiger - PPX7.com',
    titlePt: 'Cassino Ao Vivo - Fortune Tiger - PPX7.com',
  };

  const tableGamesSchemeData = {
    '@context': 'https://schema.org',
    '@type': 'OnlineCasino',
    name: 'Jogos De Mesa - Fortune Tiger - PPX7.com',
    url: 'https://www.ppx7.com/pt-br/table-games',
    logo: 'https://www.ppx7.com/logo.png',
    description: 'Jogue nas mesas do Fortune Tiger em PPX7.com, o principal cassino online do Brasil. Desfrute de um jogo emocionante e tenha a chance de ganhar muito.',
    descriptionPt: 'Jogue nas mesas do Fortune Tiger em PPX7.com, o principal cassino online do Brasil. Desfrute de um jogo emocionante e tenha a chance de ganhar muito.',
    title: 'Tables - Fortune Tiger - PPX7.com',
    titlePt: 'Jogos De Mesa - Fortune Tiger - PPX7.com',
  };

  const cardGamesSchemeData = {
    '@context': 'https://schema.org',
    '@type': 'OnlineCasino',
    name: 'PPX7 Jogos de Carta',
    url: 'https://www.ppx7.com/pt-br/card-games',
    logo: 'https://www.ppx7.com/logo.png',
    description: 'Junte-se a PPX7 para jogar os Melhores Jogos de Cartas Online',
    descriptionPt: 'Junte-se a PPX7 para jogar os Melhores Jogos de Cartas Online',
    title: 'Card Games - PPX7.com',
    titlePt: 'Jogos de Carta - PPX7.com',
  };

  const handleQueryCategory = (category) => {
    if (category) {
      switch (selectedLanguage) {
      case 'en-BR':
        switch (category) {
        case 'slots':
          setSchemaData(slotSchemeData);
          break;
        case 'instants':
          setSchemaData(instantGamesSchemeData);
          break;
        case 'live-casino':
          setSchemaData(liveCasinoSchemeData);
          break;
        case 'tables':
          setSchemaData(tableGamesSchemeData);
          break;
        case 'cards':
          setSchemaData(cardGamesSchemeData);
          break;
        default:
          break;
        }
        break;
      case 'pt-BR':
        switch (category) {
        case 'slots':
          setSchemaData(slotSchemeData);
          break;
        case 'instants':
          setSchemaData(instantGamesSchemeData);
          break;
        case 'live-casino':
          setSchemaData(liveCasinoSchemeData);
          break;
        case 'tables':
          setSchemaData(tableGamesSchemeData);
          break;
        case 'cards':
          setSchemaData(cardGamesSchemeData);
          break;
        default:
          break;
        }
        break;
      default:
        break;
      }
    }
  };

  useEffect(() => {
    if (data.query.category) {
      handleQueryCategory(data.query.category);
    }
  }, [data.query.category]);

  return (
    <>
      <NextSeo
        title={selectedLanguage === 'pt-BR' ? schemaData?.titlePt : schemaData?.title}
        description={selectedLanguage === 'pt-BR' ? schemaData?.descriptionPt : schemaData?.description}
        canonical={`${CANONICAL_URL}/${canonicalUrlEnd}`}
        languageAlternates={[
          {
            hrefLang: 'en-BR',
            href: `${CANONICAL_URL}/en-BR/${canonicalUrlEnd}`,
          },
          {
            hrefLang: 'pt',
            href: `${CANONICAL_URL}/${canonicalUrlEnd}`,
          },

        ]}
        openGraph={{
          type: 'website',
          url: `${CANONICAL_URL}/${canonicalUrlEnd}`,
          title: 'PPX7 - Online Casino Brazil | Fortune Tiger',
          description: 'PPX7 - O melhor cassino online no Brasil com jogos emocionantes como Fortune Tiger.',
          images: [
            {
              url: `${process.env.SITE_URL}/metaImageBackground.jpg`,
              width: 1200,
              height: 800,
              alt: 'PPX7 Image',
            },
          ],
          site_name: 'PPX7',
        }}
      />

      <WebPageJsonLd
        context='https://schema.org'
        type='WebPage'
        name='PPX7 - Online Casino Brazil | Fortune Tiger'
        url={`${CANONICAL_URL}`}
        description='PPX7 - O melhor cassino online no Brasil com jogos emocionantes como Fortune Tiger.'
        inLanguage='pt-BR'
        publisher={{
          type: 'Organization',
          name: 'PPX7',
          logo: {
            type: 'ImageObject',
            url: `${process.env.SITE_URL}/metaImageBackground.jpg`,
          },
        }}
        image={{
          type: 'ImageObject',
          url: `${process.env.SITE_URL}/metaImageBackground.jpg`,
          height: 800,
          width: 1200,
        }}
        potentialAction={{
          type: 'SearchAction',
          target: `${CANONICAL_URL}/search?q={search_term_string}`,
          'query-input': 'required name=search_term_string',
        }}
        mainEntityOfPage={{
          '@type': 'WebPage',
          '@id': `${CANONICAL_URL}`,
        }}
        hasPart={[
          {
            '@type': 'WebPageElement',
            name: 'Bem-vindo',
            text: 'Este é um exemplo de uma página multilíngue.',
          },
          {
            '@type': 'WebPageElement',
            name: 'Our Partners',
            text: 'Our Partners:',
          },
        ]}
        about={{
          '@type': 'Organization',
          name: 'Sigma.org',
          url: 'https://sigma.org',
          logo: {
            '@type': 'ImageObject',
            url: `${process.env.SITE_URL}/metaImageBackground.jpg`,
            caption: 'http://Sigma.org',
          },
        }}
      />
      <Layout selectedIcon={t('common.profile')}>
        <Header>
          <GameApp data={data} schemaData={schemaData} />
        </Header>
      </Layout>
    </>
  );
}

export async function getServerSideProps(context) {
  const { query, locale } = context;
  const options = {
    method: 'GET',
    url: `${ENDPOINT}/branches`,
    params: { _id: process.env.BRANCH_ID },
    headers: {
      'Content-Type': 'application/json',
    },
  };

  const res = await axios.request(options);
  const branchData = res?.data?.data[0] || {};

  let canonicalUrlEnd = 'Slots';
  switch (query.category) {
  case 'slots':
    canonicalUrlEnd = 'slots';
    break;
  case 'instants':
    canonicalUrlEnd = 'instants';
    break;
  case 'live-casino':
    canonicalUrlEnd = 'live-casino';
    break;
  case 'tables':
    canonicalUrlEnd = 'tables';
    break;
  case 'cards':
    canonicalUrlEnd = 'cards';
    break;
  default:
    canonicalUrlEnd = 'slots';
    break;
  }

  return {
    props: {
      ...(await serverSideTranslations(locale, ['common'])),
      data: {
        query,
        branchId: process.env.BRANCH_ID,
        ...branchData,
        canonicalUrlEnd,
      },
    },
  };
}
